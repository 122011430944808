import BaseController from './base-controller'

import every from 'lodash/every'
import get from 'lodash/get'

// Managers
import Features from 'editor/managers/features'

// Helpers
///////////////////////////////////
//////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////
/////////

export const setDefaultValues = (objToModify, sectionController) => {
////////////////////
/////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////
/////////////////////////
//////////////////////////////////////////////////
//////////////////////////////////////////////////
//////////////////////////////////////////
////////////
///////////////////////////////
//////////////////////////////////////////////////////////////
/////////////////////////////////////////////////
///////////
///////////////////////////////////////////////////////
/////////
////////
//////
///
///////////
  return objToModify
}

class ContentController extends BaseController {
  constructor (parentController, model) {
    super(parentController, model)

    if (this.model.dataSource) {
      this.setReadonly(true)
    }

    if (this.editable()) {
      this.convertImagesToProxy(model)
      this.convertBackgroundsToProxy(model)
    }
    this.control = new this.imports.ContentControl(this, model)

    if (!this.imports.isPublisher) {
      if (parentController.injectFeatureInstances) {
        this.control.features = parentController.injectFeatureInstances
      } else {
        Features.initializeFeatures(this.control, model)
      }
    }
  }

  convertImagesToProxy (model, imgValueFunction = null) {
    Object.entries(model)
      .filter(([key, _]) => key.indexOf('image') === 0)
      .forEach(([key, value]) => {
        if (isValidURL(value.value) && !ImageProxyHelper.isProxiedUrl(value.value)) {
          if (imgValueFunction) {
            model[key].value = (imgValueFunction())
              ? new ImageProxyHelper(value.value, 'cloudflare').result()
              : ImageProxyHelper.getProxyUrl(value.value)
          } else {
            model[key].value = ImageProxyHelper.getProxyUrl(value.value)
          }
        }
      })
  }

  convertBackgroundsToProxy (model, imgValueFunction = null) {
    Object.entries(model)
      .filter(([key, _]) => key.indexOf('background') === 0)
      .forEach(([key, value]) => {
        if (value.img && isValidURL(value.img.value) && !ImageProxyHelper.isProxiedUrl(value.img.value)) {
          if (imgValueFunction) {
            model[key].img.value = (imgValueFunction())
              ? new ImageProxyHelper(value.img.value, 'cloudflare').result()
              : ImageProxyHelper.getProxyUrl(value.img.value)
          } else {
            model[key].img.value = ImageProxyHelper.getProxyUrl(value.img.value)
          }
        }
      })
  }

  onAfterRender = () => {
    if (this.imports.isPublisher) {
      Features.publishFeatures(this)
    }
  }

  getDataFromDatasource (getCollection = false) {
    if (this.model.dataSource) {
      const siteController = this.getSiteController()
      return siteController.getDataFromDatasource(this.model.dataSource, this.model.globalLocationId, getCollection)
    }
  }

  getFeatureRenderModel () {
    if (!this.imports.isPublisher) {
      return Features.getRenderModel(this.control, this.model, this.getSiteController().getGlobalBinding(this.model))
    }
  }

  getInnerValue = (model, options = {}) => {
    const siteController = this.getSiteController()
    const sectionController = this.getSectionController()
    const pageController = sectionController.getPageController()
    const sections = pageController.getSectionControllers()
    const indexOfSection = sections.indexOf(sectionController)

    const global = siteController.getGlobalBinding(this.model)
    const featureModel = this.getFeatureRenderModel()

    const obj = {
      ...this.model,
      _index: indexOfSection,
      _sectionId: sectionController.getId(),
      global,
      navigation: siteController.getNavigation(),
      _isMainPage: sectionController.isOnMainPage(),
/////////////////////////////////////////
///////////////////////////////////
///////////////
      ...options,
      ...featureModel
    }

    if (!this.imports.isPublisher) {
      setDefaultValues(obj, sectionController)
    }

    let getCollection = this.model.getCollection
    let data = this.getDataFromDatasource(getCollection)
    // No collection when there's only 1 item in the collection
    if (getCollection && data && data.length === 1) {
      data = data[0]
      getCollection = false
    }
    if (getCollection && data && data.length) {
      const listCollection = data.map((item, index) => {
        return {
          ...item,
          title: item.name,
          footnote: item.footnote,
          description: item.description,
          items: item.items,
          active: index === 0
        }
      })
      obj.listCollection = listCollection
    } else if (data) {
      obj.title = data.name
      obj.footnote = data.footnote
      obj.description = data.description
    }

    if (!this.hasAnimations()) {
      obj.noAnimations = true
    } else {
      if (!this.editable() && this.editorContext) {
        // we are in the editor with a carousel
        obj.noAnimations = true
      }
    }
    return obj
  }

  updateView (view, model, paths) {
    const hasUpdated = every(paths, (value, path) => {
      if (this.control.updateFeatureProperty) {
        if (this.control.updateFeatureProperty(this.control, path, get(model, path))) {
          return true
        }
      }
    })
    if (!hasUpdated) {
      super.updateView(view, model, paths)
    }
  }

  updateViewForGlobalBinding (globalBinding, paths) {
    const sectionController = this.getSectionController()
    const layoutObject = sectionController.getSectionLayoutObj()
    const features = layoutObject.metadata.features
    // Rerender view when it uses features
    if (features && features.length) {
      this.control.rerender()
      return
    }

    // Check if location data changed
    const locationId = sectionController.model.binding.globalLocationId
    if (locationId) {
      if (paths['locations.' + locationId]) {
        this.control.rerender()
        return
      }
    }

    // Check if openingHours data changed
    const openinghours = sectionController.model.category === 'openinghours'
    if (openinghours && paths) {
      let needsUpdate = false
      Object.keys(paths).forEach(path => {
        if (path.includes('openingHours')) {
          needsUpdate = true
        }
      })
      if (needsUpdate) {
        this.control.rerender()
        return
      }
    }

    // Check if this control renders a globalBinding
    const fields = layoutObject.fields
    const globalChanged = fields
      .filter(i => i.indexOf('global.') === 0)
      .some(field => {
        field = field.replace('global.', '')

        const fieldMatch = Object.keys(paths).find(function (key) {
          return key.indexOf(field) > -1
        })
        return !!fieldMatch
      })
    if (globalChanged) {
      this.control.rerender()
    }
  }
}
export default ContentController
