import ListController from './controllers/readonly/list-controller'
import SiteController from './controllers/readonly/site-controller'

import ContentController from './controllers/content-controller'
import SectionController from './controllers/section-controller'

import BackgroundController from 'core/editor/controllers/background-controller'
import ListItemController from 'core/editor/controllers/list-item-controller'
import NavigationController from 'core/editor/controllers/navigation-controller'
import PageController from 'core/editor/controllers/page-controller'

// Controls
import ListControl from './controls/readonly/list-control'
import PageControl from './controls/readonly/page-control'

import BackgroundControl from './controls/background-control'
import ContentControl from './controls/content-control'
import SectionControl from './controls/section-control'
import SiteControl from './controls/site-control'

import ListItemControl from 'core/editor/controls/list-item-control'
import NavigationControl from 'core/editor/controls/navigation-control'

const imports = {
  SiteController,
  ContentController,
  BackgroundController,
  ListController,
  ListItemController,
  NavigationController,
  PageController,
  SectionController,
  // control
  BackgroundControl,
  ContentControl,
  ListControl,
  ListItemControl,
  NavigationControl,
  PageControl,
  SectionControl,
  SiteControl
}

export default imports
